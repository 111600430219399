<template>
  <div>
    <form-create
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      titulo="Nova Disciplina"
      @refetch-data="refetchData"
    />

    <b-card
      no-body
      class="mb-0"
    >
      <h5 class="card-title pt-2 pl-2 pb-0 mb-0">
        Lista de Disciplinas
      </h5>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibição</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Pesquisar..."
              />
              <b-button
                variant="success"
                @click="isAddNewSidebarActive = true"
              >
                <span class="text-nowrap">
                  Adicionar
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchDados"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum resultado encontrado"
        :sort-desc.sync="isSortDirDesc"
        :busy="isLoad"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> Carregando...</strong>
          </div>
        </template>
        <!-- Column: Nome e Imagem -->
        <template #cell(nome)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="4.5em"
                :src="data.item.imagem"
                :text="avatarText(data.item.nome)"
                :variant="'warning'"
                class="avatar-with-shadow"
              />
            </template>
            <div>{{ data.item.nome }}</div>
            <div class="d-flex">
              <tag-color
                :bg-color="data.item.bg_color"
                v-text="data.item.abreviacao"
              />
            </div>
          </b-media>
        </template>
        <!-- Column: Subdisciplinas -->
        <template #cell(subdisciplinas)="data">
          <b-media vertical-align="center">
            <div
              v-for="(item, index) in data.item.subdisciplinas"
              :key="index"
            >
              <small>
                <TooltipTextoCortado
                  :texto="item.nome"
                  :limite="25"
                />
              </small>
            </div>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(ações)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editarItem(data.item)">

              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="modalExcluir(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} dos {{ dataMeta.of }} resultados
              encontrados</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItens"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <modalConfirmacao
        id="modal-confirmacao"
        titulo="Excluir Item"
        :item-id="dadosItem.uuid"
        :item-nome="dadosItem.nome"
        @itemConfirmado="excluirItem"
        @click="modalExcluir(data.item)"
      />

      <b-modal
        id="modal-editar"
        v-model="modalShow"
        :title="`Editar ${dadosItem.nome}`"
        :no-close-on-backdrop="true"
      >
        <validation-provider
          #default="validationContext"
          name="Nome da Subdisciplina"
          rules="required"
        >
          <b-form-group
            label="Nome do Subdisciplina"
            label-for="nome-subdisciplina"
          >
            <b-form-input
              id="nome-subdisciplina"
              v-model="dadosItem.nome"
              name="subdisciplina"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Informe o nome da subdisciplina"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>

          </b-form-group>

          <b-form-group
            label="Abreviação"
            label-for="abreviacao"
          >
            <b-form-input
              id="abreviacao"
              v-model="dadosItem.abreviacao"
              name="abreviacao"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Informe a abreviação da subdisciplina"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>

          </b-form-group>
          <!-- Subdisciplinas -->
          <validation-provider
            #default="validationContext"
            name="Subdisciplinas"
            rules="required"
          >
            <b-form-group
              label="Pesquise e escolhas as Subdisciplinas"
              :state="getValidationState(validationContext)"
            >
              <select-com-pesquisa
                url="/subdisciplinas"
                placeholder="Escolha as Subdisciplinas"
                :multiple="true"
                :itens-para-edicao="dadosItem.subdisciplinas"
                @selecionados="tratarEventoTarefasSelecionadas"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <label>Cor da Tag:
            <div class="d-flex">
              <tag-color
                :texto="dadosItem.nome"
                :bg-color="dadosItem.bg_color"
              />
            </div>
          </label>
          <div class="grid-container mb-3">
            <div
              v-for="item in cores"
              :key="item.class"
              class="grid-item"
            >
              <b-form-radio
                v-model="dadosItem.bg_color"
                name="cor"
                :value="item.hexa"
              >
                <tag-color :bg-color="item.hexa" />
              </b-form-radio>

            </div>
          </div>
          <!-- imagem  -->
          <div class="d-flex justify-content-start mt-2">
            <div class="d-block">
              <b-form-file
                ref="fileInput"
                label="Imagem"
                accept="image/jpeg, image/png"
                label-for="imagem"
                browse-text="Procurar"
                placeholder="Escolha uma imagem"
                drop-placeholder="Escolha uma imagem"
                @change="handleFileChange"
              />
            </div>

          </div>
          <div
            v-if="selectedFile"
            class="d-flex justify-content-start mt-2 align-items-center"
          >
            <b-img
              thumbnail
              :src="previewUrl"
              alt="Imagem"
              :style="{ width: '50px', height: '50px' }"
            />
            <small class="ml-2">Pré visualização da imagem</small>
          </div>
        </validation-provider>
        <template #modal-footer>
          <div class="w-100">

            <b-button
              variant="primary"
              size="md"
              class="float-left"
              @click="modalShow = false"
            >
              Fechar
            </b-button>
            <b-button
              variant="success"
              size="md"
              class="float-right"
              @click="salvarItemEditado(dadosItem)"
            >
              Salvar
            </b-button>
          </div>
        </template>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadio,
  BImg,
  BMedia,
  BPagination,
  BRow,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { onUnmounted, ref } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import modalConfirmacao from '@/views/components/componentesPensou/ModalConfirmacao.vue'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'
import store from '@/store'
import vSelect from 'vue-select'
import TooltipTextoCortado from '@/views/components/componentesPensou/TooltipTextoCortado.vue'
import TagColor from '@/views/components/componentesPensou/TagColor.vue'
import useItemList from './useItemList'
import FormCreate from './FormCreate.vue'
import disciplinasStoreModule from '../displinasStoreModule'

export default {
  components: {
    FormCreate,
    BFormRadio,
    modalConfirmacao,
    SelectComPesquisa,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormGroup,
    BFormInvalidFeedback,
    vSelect,
    BFormFile,
    BImg,
    TooltipTextoCortado,
    ValidationProvider,
    TagColor,
  },

  setup() {
    const DISCIPLINAS_APP_STORE_MODULE_NAME = 'app-disciplinas'

    // Register module
    if (!store.hasModule(DISCIPLINAS_APP_STORE_MODULE_NAME)) store.registerModule(DISCIPLINAS_APP_STORE_MODULE_NAME, disciplinasStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DISCIPLINAS_APP_STORE_MODULE_NAME)) store.unregisterModule(DISCIPLINAS_APP_STORE_MODULE_NAME)
    })

    const isAddNewSidebarActive = ref(false)

    const blankItemData = {
      nome: '',
      uuid: '',
      subdisciplinas: [],
      imagem: null,
    }

    const itemData = ref(JSON.parse(JSON.stringify(blankItemData)))
    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankItemData))
    }

    const {
      fetchDados,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      deleteItem,
      update,

    } = useItemList()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    return {

      isAddNewSidebarActive,
      deleteItem,
      fetchDados,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      avatarText,
      refFormObserver,
      getValidationState,
      resetForm,
      update,

    }
  },
  data() {
    return {
      dadosItem: {
        uuid: '',
        nome: '',
        imagem: '',
        subdisciplinas: [],
      },
      cores: [
        {
          class: 'cor__padrao',
          hexa: '#336195',
          descricao: 'Padrão',
        },
        {
          class: 'cor__rosa',
          hexa: '#BA529D',
          descricao: 'Rosa',
        },
        {
          class: 'cor__azul__escuro',
          hexa: '#407abb',
          descricao: 'Azul escuro',
        },
        {
          class: 'cor__vermelho',
          hexa: '#ea5455',
          descricao: 'Vermelho',
        },
        {
          class: 'cor__azul__claro',
          hexa: '#00ade7',
          descricao: 'Azul claro',
        },
        {
          class: 'cor__laranja',
          hexa: '#ff9f43',
          descricao: 'Laranja',
        },
        {
          class: 'cor__verde__agua',
          hexa: '#00ABB9',
          descricao: 'Verde água',
        },
        {
          class: 'cor__amarelo',
          hexa: '#FFB83D',
          descricao: 'Amarelo',
        },
        {
          class: 'cor__verde',
          hexa: '#73e264',
          descricao: 'Verde',
        },
        {
          class: 'cor__cinza',
          hexa: '#ced4da',
          descricao: 'Cinza',
        },
        {
          class: 'cor__marrom',
          hexa: '#6E4C0F',
          descricao: 'Marrom',
        },
        {
          class: 'cor__preto',
          hexa: '#4b4b4b',
          descricao: 'Preto',
        },

      ],
      modalShow: false,
      selectedFile: null,
      previewUrl: null,

    }
  },
  computed: {
    isLoad() {
      return this.$store.state['app-disciplinas'].load
    },

  },

  methods: {
    editarItem(item) {
      this.dadosItem = { ...item }
      this.modalShow = true
    },

    excluirItem(dados) {
      this.deleteItem(dados)
    },

    tratarEventoTarefasSelecionadas(itens) {
      this.dadosItem.subdisciplinas = itens
    },

    handleFileChange(event) {
      const file = event.target.files[0]
      this.selectedFile = file
      this.dadosItem.imagem = file

      if (file) {
        const reader = new FileReader()
        reader.onload = () => {
          this.previewUrl = reader.result
        }
        reader.readAsDataURL(file)
      } else {
        this.previewUrl = null
      }
    },

    modalExcluir(item) {
      this.dadosItem.nome = item.nome
      this.dadosItem.uuid = item.uuid
      this.$root.$emit('bv::show::modal', 'modal-confirmacao')
    },

    salvarItemEditado(dadosItem) {
      this.$root.$emit('bv::hide::modal', 'modal-editar')

      this.update(dadosItem)
      this.selectedFile = null
      this.previewUrl = null
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.per-page-selector {
  width: 90px;
}

.grid-container {
  margin-top: 12px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;

}

.grid-item {
  padding: 4px;
}
</style>
