import axios from '@axios'

export default {
  namespaced: true,
  state: {
    load: false,
  },
  getters: {
    getLoad(state) {
      return state.load
    },
  },
  mutations: {
    alteraLoading(state, data) {
      state.load = data
    },
  },
  actions: {
    deleteItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/disciplinas/${id}`)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },
    fetchDados({ commit }, queryParams) {
      commit('alteraLoading', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/disciplinas', { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch(error => console.log(error))
      })
    },

    store(ctx, itemData) {
      const formData = new FormData()

      formData.append('nome', itemData.nome)
      formData.append('abreviacao', itemData.abreviacao)
      formData.append('bg_color', itemData.bg_color)
      formData.append('text_color', '#FFFFFF')

      const listaSubdisciplinas = itemData.subdisciplinas.map(item => {
        if (item.id) {
          return item.id
        }
        return item.uuid
      })

      Object.keys(listaSubdisciplinas)
        .forEach(chave => {
          formData.append('subdisciplinas[]', listaSubdisciplinas[chave])
        })

      if (itemData.imagem) {
        formData.append('imagem', itemData.imagem)
      }

      return new Promise((resolve, reject) => {
        axios
          .post('/disciplinas', formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    show(ctx, item) {
      return new Promise((resolve, reject) => {
        axios
          .get(`disciplinas/${item.id.uuid}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    update(ctx, itemData) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('abreviacao', itemData.abreviacao)
        formData.append('nome', itemData.nome)
        formData.append('bg_color', itemData.bg_color)
        formData.append('text_color', '#FFFFFF')

        if (itemData.imagem && typeof (itemData.imagem) !== 'string') {
          formData.append('imagem', itemData.imagem)
        }

        const listaSubdisciplinas = itemData.subdisciplinas.map(item => {
          if (item.id) {
            return item.id
          }
          return item.uuid
        })

        Object.keys(listaSubdisciplinas)
          .forEach(chave => {
            formData.append('subdisciplinas[]', listaSubdisciplinas[chave])
          })

        formData.append('_method', 'PUT')

        axios
          .post(`disciplinas/${itemData.uuid}`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
