<template>
  <b-sidebar
    id="add-new-sidebar"
    :visible="isAddNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titulo }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <validation-provider
            #default="validationContext"
            name="Nome da Disciplina"
            rules="required"
          >
            <b-form-group
              label="Nome da Disciplina"
              label-for="nome-disciplina"
            >
              <b-form-input
                id="nome-disciplina"
                v-model="itemData.nome"
                name="nome_disciplina"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Informe o nome da disciplina"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Abreviação"
            rules="required"
          >
            <b-form-group
              label="Abreviação"
              label-for="abreviacao"
            >
              <b-form-input
                id="abreviacao"
                v-model="itemData.abreviacao"
                name="abreviacao"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Informe a abreviação da disciplina"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Subdisciplinas -->

          <b-form-group
            label="Escolha as Subdisciplinas"
          >
            <select-com-pesquisa
              url="/subdisciplinas"

              placeholder="Escolha as Subdisciplinas"
              :multiple="true"
              :itens-para-edicao="[]"
              @selecionados="tratarEventoTarefasSelecionadas"
            />

          </b-form-group>

          <!-- imagem  -->
          <div class="d-flex justify-content-start mt-2">
            <div class="d-block">
              <b-form-file
                ref="fileInput"
                label="Imagem"
                accept="image/jpeg, image/png"
                label-for="imagem"
                browse-text="Procurar"
                placeholder="Escolha uma imagem"
                drop-placeholder="Escolha uma imagem"
                @change="handleFileChange"
              />
            </div>

          </div>
          <div
            v-if="selectedFile"
            class="d-flex justify-content-start mt-2 align-items-center"
          >
            <b-img
              thumbnail
              :src="previewUrl"
              alt="Imagem"
              :style="{ width: '50px', height: '50px' }"
            />
            <small class="ml-2">Pré visualização da imagem</small>
          </div>
          <label class="mt-2">Escolha a cor da Tag: </label>
          <div class="grid-container mb-3">
            <div
              v-for="item in cores"
              :key="item.class"
              class="grid-item"
            >
              <b-form-radio
                v-model="itemData.bg_color"
                name="cor"
                :value="item.hexa"
              >
                <tag-color :bg-color="item.hexa" />
              </b-form-radio>

            </div>
          </div>
          <hr>
          <!-- Form Actions -->
          <div class="d-flex ">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BBadge,
  BButton,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadio,
  BImg,
  BSidebar,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import vSelect from 'vue-select'
import TagColor from '@/views/components/componentesPensou/TagColor.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BFormCheckbox,
    SelectComPesquisa,
    vSelect,
    BImg,
    BBadge,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    TagColor,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    titulo: {
      type: String,
      required: true,
    },

  },
  data() {
    return {
      required,
      selectedFile: null,
      previewUrl: null,
      cores: [
        {
          class: 'cor__padrao',
          hexa: '#336195',
          descricao: 'Padrão',
        },
        {
          class: 'cor__rosa',
          hexa: '#BA529D',
          descricao: 'Rosa',
        },
        {
          class: 'cor__azul__escuro',
          hexa: '#407abb',
          descricao: 'Azul escuro',
        },
        {
          class: 'cor__vermelho',
          hexa: '#ea5455',
          descricao: 'Vermelho',
        },
        {
          class: 'cor__azul__claro',
          hexa: '#00ade7',
          descricao: 'Azul claro',
        },
        {
          class: 'cor__laranja',
          hexa: '#ff9f43',
          descricao: 'Laranja',
        },
        {
          class: 'cor__verde__agua',
          hexa: '#00ABB9',
          descricao: 'Verde água',
        },
        {
          class: 'cor__amarelo',
          hexa: '#FFB83D',
          descricao: 'Amarelo',
        },
        {
          class: 'cor__verde',
          hexa: '#73e264',
          descricao: 'Verde',
        },
        {
          class: 'cor__cinza',
          hexa: '#ced4da',
          descricao: 'Cinza',
        },
        {
          class: 'cor__marrom',
          hexa: '#6E4C0F',
          descricao: 'Marrom',
        },
        {
          class: 'cor__preto',
          hexa: '#4b4b4b',
          descricao: 'Preto',
        },

      ],

    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      nome: '',
      subdisciplinas: [],
      imagem: null,

    }

    const toast = useToast()

    const itemData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('app-disciplinas/store', itemData.value)
        .then(respo => {
          emit('refetch-data')
          emit('update:is-add-new-sidebar-active', false)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Disciplina adicionada com sucesso',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            const listaErros = error.response.data.errors
            for (const field in listaErros) {
              if (listaErros.hasOwnProperty(field)) {
                const errorMessages = listaErros[field]
                errorMessages.forEach(errorMessage => {
                  toast({
                    component: ToastificationContent,
                    props: {
                      title: errorMessage,
                      icon: 'AlertCircleIcon',
                      variant: 'danger',
                    },
                  })
                })
              }
            }
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    return {
      itemData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },

  methods: {

    handleFileChange(event) {
      const file = event.target.files[0]
      this.selectedFile = file
      this.itemData.imagem = file

      if (file) {
        const reader = new FileReader()
        reader.onload = () => {
          this.previewUrl = reader.result
        }
        reader.readAsDataURL(file)
      } else {
        this.previewUrl = null
      }
    },
    tratarEventoTarefasSelecionadas(itens) {
      this.itemData.subdisciplinas = itens
    },

  },

}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.select_color {
  justify-content: space-around;
  justify-content: flex-start;
  list-style: none;
}

.grid-container {
  margin-top: 12px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;

}

.grid-item {
  padding: 4px;
}

#add-new-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
