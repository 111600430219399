import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";

export default function useItemList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "nome", sortable: true },
    { key: "subdisciplinas" },
    { key: "Ações" },
  ];
  const perPage = ref(10);
  const totalItens = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    currentPage.value - 1;

    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItens.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery], (newVal, oldVal) => {
    refetchData();
  });

  const fetchDados = (ctx, callback) => {
    store
      .dispatch("app-disciplinas/fetchDados", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        callback(response.data);
        totalItens.value = response.total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao listar as disciplinas",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const update = (dadosItem) => {
    store.dispatch("app-disciplinas/update", dadosItem).then(() => {
      refetchData();

      toast({
        component: ToastificationContent,
        props: {
          title: "Disciplina editada com sucesso",
          icon: "CheckCircleIcon",
          variant: "success",
        },
      });
    });
  };

  const deleteItem = (id) => {
    store.dispatch("app-disciplinas/deleteItem", id).then(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: "Disciplina excluída com sucesso",
          icon: "CheckCircleIcon",
          variant: "success",
        },
      });
      refetchData();
    });
  };

  return {
    fetchDados,
    update,
    deleteItem,
    tableColumns,
    perPage,
    currentPage,
    totalItens,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    refetchData,
  };
}
